import { onAuthStateChanged } from "firebase/auth"


function showElems(...args) {
    args.forEach((el, i) => {
      el.classList.remove('invisible')
      el.classList.remove('hidden')
    })
}

function hideElems(hideClass='invisible', ...args) {
    args.forEach((el, i) => {
      el.classList.add(hideClass)
    })
}

function disableElems(...args) {
    args.forEach((el, i) => {
      el.classList.add('cursor-not-allowed')
      el.setAttribute('disabled', true)
    })
}

function enableElems(...args) {
    args.forEach((el, i) => {
      el.classList.remove('cursor-not-allowed')
      el.removeAttribute('disabled')
    })
}

function validateForm(el) {
  if (!el.checkValidity()) {
    el.reportValidity()
    return
  } 
  return true
}

function generateShortID() {
  return Math.random().toString(36).substr(2, 9)
}

function generateContentID(str) {
  // generate an ID from content. Ideally you'd use hashing to generate a unique ID based on the content. Here we are using base 64 as MD5 is not easily available in browser JS.
  const base64str = btoa(str)
  // removing +, / and = from base64str to make it URL safe
  return base64str.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')
}

function sanitizeLink(url, protocols) {
  let anchor = document.createElement('a');
  anchor.href = url;
  let protocol = anchor.href.slice(0, anchor.href.indexOf(':'));
  return protocols.indexOf(protocol) > -1;
}

function encodeToBase64(str) {
  const encodedURI = encodeURIComponent(str);
// Convert the encoded URI component, which is safe in Latin1 encoding, to base64. btoa requires a string of Latin1 characters or it will throw an exception.
  const base64 = btoa(encodedURI);
  return base64;
}

function decodeFromBase64(base64String) {
  const decodedURI = atob(base64String);
  const str = decodeURIComponent(decodedURI);
  return str;
}

function checkAuthState(auth, target_uri, startUrl='/sign_in') {
  // if url starts with /share/ return
  if (window.location.href.includes('/share/')) {
    return
  }
  onAuthStateChanged(auth, (user) => {  
    if (!user) {
      if (!window.location.href.includes('/sign_')) {
        window.location.href = startUrl
      }
    } else {
      user.getIdToken().then((idToken) => {
        document.cookie = `access_token=${idToken};path=/;max-age=3300`
      })
      if (target_uri) {
        window.location.href = target_uri
      }
    }
  })
}


export { showElems, hideElems, disableElems, enableElems, validateForm, generateShortID, generateContentID, sanitizeLink, encodeToBase64, decodeFromBase64, checkAuthState}