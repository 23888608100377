import { Controller } from "@hotwired/stimulus"
import { initializeApp } from "firebase/app"
import { getAuth, signInWithEmailAndPassword} from "firebase/auth"
import { showElems, hideElems, disableElems, enableElems, checkAuthState } from '../helpers.js'

const firebaseConfig = {
    apiKey: "AIzaSyBEeVWBYFHtjBYe9grDCxt4T0ftjQRPg8Y",
    authDomain: "autho.zango.ai",
    projectId: "bright-modem-417310",
    storageBucket: "bright-modem-417310.appspot.com",
    messagingSenderId: "267246851117",
    appId: "1:267246851117:web:42c470350dcb5cd4de94a4",
    measurementId: "G-DK0CP98TTQ"
  }

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)

export default class extends Controller {
  static targets = ['username', 'password', 'error', 'button']

  connect() {
    checkAuthState(auth, '/')
  }

  initialize() {
    // classname is present during load in template.html to allow purgeCss to detect class usage
    this.buttonTarget.classList.remove('cursor-not-allowed')
  }

  doSignIn(event) {
    event.preventDefault()
    hideElems('hidden', this.errorTarget)
    disableElems(this.buttonTarget)

    let whiteSpace = /\s/gi;
    let username = this.usernameTarget.value.replace(whiteSpace, '')

    let password = this.passwordTarget.value

    signInWithEmailAndPassword(auth, username, password)
      .then((userCredential) => {
        userCredential.user.getIdToken().then((idToken) => {
          document.cookie = `access_token=${idToken};path=/;max-age=3300`
        })
        window.location.href = '/'
      })
      .catch((error) => {
        enableElems(this.buttonTarget)
        showElems(this.errorTarget)
      })
  }

}