import { Controller } from "@hotwired/stimulus"
import { decodeFromBase64 } from "../helpers"   

export default class extends Controller {
    static targets = ["pdfContainer"]

    connect() {
        const url = decodeFromBase64(this.pdfContainerTarget.getAttribute('data-url'))
        const page = this.pdfContainerTarget.getAttribute('data-page')
        const citation = decodeURIComponent(decodeFromBase64(this.pdfContainerTarget.getAttribute('data-citation')))
        renderPDF(url, page, citation, this.pdfContainerTarget)

    }

}