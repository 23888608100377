import Hotkeys from 'stimulus-hotkeys'
import * as Turbo from "@hotwired/turbo"

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"


const application = Application.start()
const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

application.register('hotkeys', Hotkeys)